import defaultDict, { headers as defaultHeaders } from './io.ox/core.en_US';
function printf(str, params) {
    let index = 0;
    return str
        .replace(/%(([0-9]+)\$)?[A-Za-z]/g, (_match, pos, n) => {
        if (pos)
            index = n - 1;
        const val = params[index++];
        return val !== undefined ? String(val) : 'unknown';
    })
        .replace(/%%/g, '%');
}
function parsePluralHeader(header) {
    const matches = header?.match(/plural=([^;]+)/);
    return matches ? matches[1] : '(n!=1)';
}
const languages = ['bg_BG', 'ca_ES', 'cs_CZ', 'da_DK', 'de_DE', 'el_GR', 'en_GB', 'en_US', 'es_ES', 'es_MX', 'et_EE', 'fi_FI', 'fr_CA', 'fr_FR', 'hu_HU', 'it_IT', 'ja_JP', 'lv_LV', 'mn_MN', 'nb_NO', 'nl_NL', 'pl_PL', 'pt_BR', 'ro_RO', 'ru_RU', 'sk_SK', 'sv_SE', 'tr_TR', 'zh_CN', 'zh_TW'];
export class Dictionary {
    namespace;
    _headers;
    dict;
    plural;
    constructor(namespace, headers, dict) {
        this.namespace = namespace;
        this.headers = headers;
        this.dict = dict;
    }
    set headers(headers) {
        this._headers = headers;
        const plural = parsePluralHeader(this.headers['Plural-Forms']);
        // eslint-disable-next-line no-new-func, @typescript-eslint/no-implied-eval
        this.plural = new Function('n', `return Number(${plural});`);
    }
    get headers() {
        return this._headers;
    }
    async changeLanguage(language) {
        if (language === this.headers.Language)
            return;
        if (!languages.includes(language))
            throw new Error(`Language not found: ${language} (in ${languages.join(', ')})`);
        // prevent rollup dynamic import vars plugin from detecting this import
        // this is only needed in case the plugin is in use, but doesn't hurt
        let path;
        try {
            path = new URL(`${this.namespace}.${language}.js`, window.location.href).href;
        }
        catch (err) {
            path = `./${this.namespace.replace(/.*\//, '')}.${language}.js`;
        }
        const { default: dict, headers } = await import(/* @vite-ignore */ path);
        headers.Language = language;
        this.headers = headers;
        this.dict = dict;
    }
    gettext(str, ...args) {
        return this.npgettext('', str, '', 1, ...args);
    }
    pgettext(context, str, ...args) {
        return this.npgettext(context, str, '', 1, ...args);
    }
    ngettext(singular, plural, count, ...args) {
        return this.npgettext('', singular, plural, count, ...args);
    }
    npgettext(context, singular, plural, count, ...args) {
        const translations = this.dict[`${context}\x00${singular}\x01${plural}`] || [];
        if (translations.length === 0)
            translations.push(singular, plural);
        const translation = translations[this.plural(Number(count))] || translations[0];
        return args.length ? printf(translation, args) : translation;
    }
    noI18n(a) {
        return a;
    }
}
defaultHeaders.Language = 'en_US';
export const dictionary = new Dictionary('io.ox/core', defaultHeaders, defaultDict);
function simpleGt(str, ...args) {
    return dictionary.npgettext('', str, '', 1, ...args);
}
export const gt = new Proxy(simpleGt, {
    get(_target, prop) {
        const value = dictionary[prop];
        return (typeof value === 'function') ? value.bind(dictionary) : value;
    }
});
export default gt;
