export var headers = {
	"Project-Id-Version": "Open-Xchange 7",
	"Report-Msgid-Bugs-To": "",
	"POT-Creation-Date": "2016-06-22 11:58+0000",
	"PO-Revision-Date": "2017-01-09 10:15+0100",
	"Last-Translator": "NAME <EMAIL>",
	Language: "en_US",
	"Language-Team": "OX <i18n@open-xchange.com>",
	"Content-Type": "text/plain; charset=UTF-8",
	"Content-Transfer-Encoding": "8bit",
	"Plural-Forms": "nplurals=2; plural=(n!=1);",
	"MIME-Version": "1.0",
	"X-Generator": "Poedit 1.8.9"
};
export default {
	headers: headers,
	"\u0000%1$s\n%2$s %3$s\n%4$s\n%5$s\u0001": [
	"%1$s\n%3$s\n%4$s\n%2$s\n%5$s"
]
};
